@use '../border';

.about-content {
    padding: 0.5em;
    max-width: 30em;
    margin: 0 auto;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (orientation:portrait) {
        font-size: smaller;
    }
}

.start-game {
    display: block;
    width: fit-content;
    margin: 1em auto;
    padding: 0.5em 1em;
    background-color: palegreen;
    color: inherit;
    text-decoration: none;
    font-size: 2em;
    font-weight: bold;

    @include border.button;
    border-width: 0.1em;
    border-color: palegreen;
    border-radius: 0.4em;

    @media (prefers-color-scheme:dark) {
        background-color: green;
    }
}