.rps-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: 100%;

    @media (orientation:portrait) {
        flex-direction: column;
        gap: 5vh;
    }
}

.result {
    display: flex;
    flex-direction: row;
    gap: min(1vh, 1vw);
    justify-content: space-evenly;
    max-width: 100%;
    margin-top: min(2vw, 2vh);

    @media (orientation:landscape) {
        max-height: 100%;
        vertical-align: center;
    }
}