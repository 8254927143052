.player {
    position: relative;
}

.winner {
    background-color: hsl(120, 93%, 90%);
    border: min(1vh, 1vw) outset hsl(120, 93%, 90%);
    border-radius: min(5vh, 5vw);

    @media (prefers-color-scheme:dark) {
        background-color: hsl(120, 93%, 10%);
    }
}

.loser {
    background-color: hsl(340, 57%, 90%);
    border: min(1vh, 1vw) outset hsl(340, 57%, 90%);
    border-radius: min(5vh, 5vw);

    @media (prefers-color-scheme:dark) {
        background-color: hsl(340, 57%, 10%);
    }
}

.tie {
    background-color: hsl(0, 0%, 90%);
    border: min(1vh, 1vw) outset hsl(0, 0%, 90%);
    border-radius: min(5vh, 5vw);

    @media (prefers-color-scheme:dark) {
        background-color: hsl(0, 0%, 10%);
    }
}

.score {
    display: block;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 40vh;
    font-size: 3em;

    @media (orientation:portrait) {
        top: 15vh;
    }

    @media (prefers-color-scheme:dark) {
        color: #000;
    }
}