html {
    color: #000;
    background-color: #fff;

    @media (prefers-color-scheme:dark) {
        color: #fff;
        background-color: #000;
    }
}

html,
body {
    padding: 0;
    margin: 0;
}